.theme_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 15px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}

.ball {
  height: 15px;
  width: 15px;
  position: absolute;
  border-radius: 50px;
}

.invoice {
  padding: 1vw 1.5vw;
  position: relative;
}

.invoice::before {
  content: "";
  background-image: url("./merinasoft.jpg"); /* Replace 'path/to/your/image.jpg' with the actual path to your image */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.04; /* Adjust the opacity value as needed (ranges from 0 to 1) */
}

.invoice_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading_left {
  text-align: center;
}

.heading_left > h2 {
  text-align: left;
  font-size: 3.3rem;
}
.heading_left > h5 {
  margin-left: 20px;
  margin-top: -15px;
  font-size: 1.1rem;
  color: #06341b;
}
.green {
  color: #671585;
  margin-right: -12px;
}
.blue {
  color: #06341b;
  margin-left: 5px;
}
.quatationTablediv {
  margin-top: 20px;
  min-height: 450px;
}

.Quotation_text {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}
.heading_right {
}
.property {
}
.property h3 {
  font-size: 1.2rem;
}
.property h5 {
  font-size: 0.8rem;
}
.prheaderTh {
  background-color: #06341b;
  font-weight: bold;
  font-size: 20px;
}
.heading_hr {
  width: 100%;
  height: 0px;
  border: 1px dotted black;
  margin-top: 15px;
  border-bottom: none;
}
.prtableTd {
  font-size: 18px;
  border: 1px solid gray;
}
.customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px auto;
}

.customer_left > h3 {
  font-size: 1.2rem;
}
.customer_right > h3 {
  font-size: 1.2rem;
}
.invoice_footer {
}
.tAmount {
  background-color: #06341b;
  color: white;
  padding: 10px;
  width: 350px;
  margin-top: 20px;
  font-size: 1.8rem;
  border-radius: 5px;
}
.authorize {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 10px;
}
.authorize_left {
}
.authorize_right {
}
.authorize_hr {
  border: 0.5px solid;
  border-bottom: none;
}
.condition {
  margin-top: 15px;
}
.condition > h3 {
  font-size: 1rem;
}

.thank_text {
  text-align: center;
}

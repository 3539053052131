/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
* {
  font-family: Arial, Helvetica, sans-serif;
}
.table_div_supershop_purchase {
  scrollbar-color: rgba(136, 179, 154, 1) rgba(187, 210, 198, 1);
}

.table_div_supershop_purchase::-webkit-scrollbar {
  width: 12px;
}

.table_div_supershop_purchase::-webkit-scrollbar-track {
  background: rgba(187, 210, 198, 1);
}

.full_div_purchases_report {
  display: flex;
  flex-direction: column;
  row-gap: 0.4vw;
  width: 100vw;
  padding-bottom: 0.5vw;
  background-color: #dde0de;
}
.purchases_report_input {
  display: flex;
  flex-direction: column;
}
.purchases_report_input_date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.date_search_input_purchase_report {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.navbars {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -0.2vw;
  background: rgb(1, 63, 29);
  height: 4.8vh;
  justify-content: space-between;
  animation-name: effectTop;
  animation-duration: 1s;
}
.update_button_purchses_report {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5vw;
  font-size: 1vw;
  font-weight: bold;
}
.Second_update_button_purchses_report {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5vw;
  font-size: 1vw;
  font-weight: bold;
}
.update_button_purchses_report button {
  width: 4vw;
  height: 3.7vw;
  display: flex;
  flex-direction: column;
  row-gap: 0.5vw;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 4.4px;
  cursor: pointer;
  background-color: rgba(6, 52, 27, 1);
}
.purchase_report_search_button button {
  background-color: rgba(6, 52, 27, 1);
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4.4px;
  border: none;
  border: none;
  width: 5.5vw;
  height: 2vw;
}
.update_button_purchses_report img {
  width: 3vw;
  margin-top: 0.3vw;
  height: 3vw;
}
.reset_button_purchses_report {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 0.5vw;
  font-size: 1vw;
  font-weight: bold;
}
.reset_button_purchses_report button {
  width: 4vw;
  height: 3.7vw;
  display: flex;
  flex-direction: column;
  row-gap: 0.5vw;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 4.4px;
  cursor: pointer;
  background-color: rgba(6, 52, 27, 1);
}
.reset_button_purchses_report img {
  width: 3.2vw;
  margin-top: 0.2vw;
  height: 3vw;
}
.Second_update_button_purchses_report button {
  width: 4vw;
  height: 3.7vw;
  display: flex;
  flex-direction: column;
  row-gap: 0.5vw;
  align-items: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 4.4px;
  cursor: pointer;
  background-color: rgba(6, 52, 27, 1) !important;
}
.Second_update_button_purchses_report img {
  width: 3.8vw;
  margin-top: 0.2vw;
  height: 3.3vw;
}
.first_column_second_row_input_field_purchase_report {
  display: flex;
  column-gap: 0.6vw;
}
.show_all_purchase_button {
  position: absolute;
  right: 3.5vw;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.3vw !important;
  height: 10vh;
}
.show_all_purchase_button button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;
  font-size: 2vw;
  color: #dde0de;
  background-color: #06341b;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.first_row_div_purchase_report {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 98.2vw;
  padding-right: 0.2vw;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  border: 1px solid white;
}

.third_row_div_purchase {
  padding: 0.3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 2vh;
  width: 98vw;
  height: 32.6vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  position: relative;
  margin-top: 0.2vw;
  border: 1px solid white;
}
.second_row_div_purchase_report {
  display: flex;
  flex-direction: row;
  column-gap: 2vh;
  width: 98.5vw;
  height: 37vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  position: relative;
  margin-top: 0.2vw;
  border: 1px solid white;
}
.first_column_second_row_purchase_report {
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  padding-left: 1.4vw;

  padding-top: 1vw;
  row-gap: 1vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 2vh;
  transition: 0.5s;
  background-color: rgba(136, 179, 154, 1);
  height: 28.9vh;
  width: 67vw;
  animation-name: effectLeft;
  animation-duration: 1s;
  border: 1px solid white;
}
.all_update_button_purchses_report {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 4vw;
  margin-top: -2.6vw;
  margin-right: 4.5vw;
}
.invisible_div_purchase_report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 97.5vw;
  height: 20vh;
}
.input_field_supershop_purchase {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px !important;
  grid-column: input;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 8vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.text_area_field_supershop_purchase {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 6.5vw;
  height: 3.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_short_select {
  display: flex;
  flex-direction: row;
}
.input_field_short_select1 {
  display: flex;
  flex-direction: row;
  margin-top: 0.4vw;
}
.input_field_short_select select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_short_select1 select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_supershop_purchase_short {
  width: 11vw !important;
  border-radius: 4.4px !important;
}

.button_first_column_second_row {
  display: flex;
  flex-direction: row;
  column-gap: 10vw;
}

.quantity_add_purchaes_report {
  width: 8.2vw !important;
}
.date_input_field_short_long_purchase_report {
  display: flex;
  align-items: center;
  padding-bottom: 0vw !important;
}
.date_input_field_short_long_purchase_report input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.7vh;
  padding-left: 0.3vw;
  border: 1px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.date_input_field_short_long_purchase_report label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 7vw;
  height: 3vh;
  font-weight: bold;
}
.date_input_field_short_long_purchase_report button {
  margin-left: 1vw;
  border: none;
  width: 5.5vw;
  height: 1.8vw;
  background-color: rgba(6, 52, 27, 1) !important;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4.4px;
}
.button_title {
  margin-top: 0.5vw;
  font-weight: bold;
  font-size: 1vw;
  font-weight: bold;
  padding-top: 0.5vh;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.unit_add_purchaes_report {
  width: 3.6vw !important;
  margin-left: 0.2vw;
  height: 3.6vh !important;
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border: none;
  border-radius: 3px;
}

.label_field_supershop_purchase_report {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.3vw;
  width: 7vw;
  height: 3.2vh;
  font-weight: bold;
}
.input_field_short_long_purchse_report select {
  height: 1.6vw;
}

.invisible_div_sale_col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 20vw;
  height: 23vh;
  margin-left: 5vw;
  margin-right: 1vw;
  background-color: lightsteelblue;
  animation-name: effectLeft;
  animation-duration: 1s;
}

.invisible_div_sale_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 30vw;
  height: 23vh;
  margin-left: 1vw;
  margin-right: 2vw;
  background-color: lightsteelblue;
  animation-name: effectLeft;
  animation-duration: 1s;
}
.cart_icon_purchase {
  font-size: 2.5vw;
  color: rgba(29, 126, 75, 1);
}
.table_div_supershop_purchase {
  box-shadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
  border-radius: 1vh;
  margin-bottom: 1vh;
  width: 97vw;
  height: 30vh;
  background-color: rgba(136, 179, 154, 1);
  overflow-y: scroll;
}
.loader_spriner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-row {
  background-color: lightblue;
}

.barcode_check_box {
  margin-left: 0.7vw;
  margin-top: 0.2vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
}
.table_supershop_purchase_report {
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  transition: 0.5s;
  margin-top: 0.6vw;
  margin-left: 0.8vw;
  margin-right: 0.4vw;
  margin-bottom: 0.3vw;
  width: 97vw;
  height: 36vh;
  animation-name: effectLeft;
  animation-duration: 2s;
  justify-content: center;
}
.input_field_short_long_purchse_report button {
  background-color: rgba(6, 52, 27, 1) !important;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4.4px;
}

.second_column_second_row_purchase_report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 0.2vw;
  padding-right: 1vw;
  margin-right: 0.5vw;
  column-gap: 3vw;
  width: 23vw;
  height: 29.6vh;
  background-color: rgba(136, 179, 154, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 2vh;
  border: 1px solid white;
  transition: 0.5s;
  animation-name: effectRight;
  animation-duration: 1s;
  position: relative;
}

.bar_inside_total_div_supershop_purchase {
  background: rgba(6, 52, 27, 1);
  width: 23vw;
  height: 4vh;
  position: absolute;
  bottom: 10.4vh;
  margin-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input_field_long {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
}

.input_field_longs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
}
.input_field_longs input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 15vw;
  height: 5.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  padding-left: 0.3vw;
}

.input_field_short_long_purchse_report input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 0.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.table_supershop_purchase th {
  position: sticky;
  top: 0;
  background: rgba(6, 52, 27, 1);
  color: #cac3c3;
  width: 100vw;
}

.table_div_supershop_purchase table tr td {
  text-align: center;
  cursor: pointer !important;
  font-weight: 400;
  font-size: 0.9vw;
}
.table_div_supershop_purchase table tr:hover {
  background-color: gray;
}
.active-row {
  background-color: #1b7542;
  color: #fff;
}
.input_field_short_long_purchse_report label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  width: 8vw;
  height: 3.2vh;
  font-weight: bold;
}
.input_field_short_long_purchse_report button {
  margin-left: 1vw;
  border: none;
  width: 5.5vw;
  height: 1.7vw;
}
.input_field_short {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
}
.input_field_short_long_purchse_report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
}
.input_field_short_total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
  margin-left: 49vw;
}

.input_field_short input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 6.5vw;
  height: 2.6vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  font-size: 20px;
  color: rgb(8, 51, 26);
  font-weight: bold;
}
.notFound_text{
  font-size: 16px;
  font-weight: 200;
  color: rgb(68, 66, 66);
}

.input_field_purchase_report {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  padding: 0.6vw;
  display: flex;
  column-gap: 0.5vw;
  margin-left: 0.3vw;
  width: 98vw;
  height: 16vh;
  border-radius: 1vw;
  border: 1px solid white;
  border-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation-name: effectTop;
  animation-duration: 1s;
}
.input_field_short_long_purchse_report input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.8vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  padding-left: 0.3vw;
}
.input_field_short_long_purchse_report label {
  box-shadow: none;
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 7vw;
  height: 3vh;
  font-weight: bold;
}
.input_field_short label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 5vw;
  height: 3vh;
  font-weight: bold;
}
.bar_inside_label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 5vw;
  height: 3.2vh;
  font-weight: bold;
}
@keyframes effectLeft {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-2vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectbuttom {
  0% {
    transform: translateY(10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  5% {
    visibility: hidden;
    opacity: 0.05;
    box-shadow: 2px 2px 125px 2px rgba(145, 141, 141, 0.5);
  }
  10% {
    visibility: visible;
    opacity: 0.1;
    box-shadow: 2px 2px 120px 2px rgba(145, 141, 141, 0.5);
  }
  15% {
    visibility: visible;
    opacity: 0.15;
    box-shadow: 2px 2px 110px 2px rgba(145, 141, 141, 0.5);
  }
  20% {
    visibility: visible;
    opacity: 0.2;
    box-shadow: 2px 2px 100px 2px rgba(145, 141, 141, 0.5);
  }

  25% {
    visibility: hidden;
    opacity: 0.25;
    box-shadow: 2px 2px 95px 2px rgba(145, 141, 141, 0.5);
  }
  30% {
    visibility: visible;
    opacity: 0.3;
    box-shadow: 2px 2px 90px 2px rgba(145, 141, 141, 0.5);
  }
  35% {
    visibility: visible;
    opacity: 0.35;
    box-shadow: 2px 2px 85px 2px rgba(145, 141, 141, 0.5);
  }
  40% {
    visibility: visible;
    opacity: 0.4;
    box-shadow: 2px 2px 80px 2px rgba(145, 141, 141, 0.5);
  }
  45% {
    visibility: hidden;
    opacity: 0.45;
    box-shadow: 2px 2px 75px 2px rgba(145, 141, 141, 0.5);
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    box-shadow: 2px 2px 70px 2px rgba(145, 141, 141, 0.5);
  }
  55% {
    visibility: visible;
    opacity: 0.55;
    box-shadow: 2px 2px 65px 2px rgba(145, 141, 141, 0.5);
  }
  60% {
    visibility: visible;
    opacity: 0.6;
    box-shadow: 2px 2px 60px 2px rgba(145, 141, 141, 0.5);
  }
  65% {
    visibility: visible;
    opacity: 0.65;
    box-shadow: 2px 2px 55px 2px rgba(145, 141, 141, 0.5);
  }
  70% {
    visibility: hidden;
    opacity: 0.7;
    box-shadow: 2px 2px 50px 2px rgba(145, 141, 141, 0.5);
  }
  75% {
    visibility: visible;
    opacity: 0.75;
    box-shadow: 2px 2px 45px 2px rgba(145, 141, 141, 0.5);
  }
  80% {
    visibility: visible;
    opacity: 0.8;
    box-shadow: 2px 2px 40px 2px rgba(145, 141, 141, 0.5);
  }
  85% {
    visibility: visible;
    opacity: 0.85;
    box-shadow: 2px 2px 15px 2px rgba(145, 141, 141, 0.5);
  }
  90% {
    visibility: visible;
    opacity: 0.9;
    box-shadow: 2px 2px 10px 2px rgba(145, 141, 141, 0.5);
  }
  95% {
    visibility: visible;
    opacity: 0.95;
    box-shadow: 2px 2px 5px 2px rgba(145, 141, 141, 0.5);
  }
  100% {
    visibility: visible;
    opacity: 1;
    box-shadow: 2px 2px 4px 2px rgba(145, 141, 141, 0.5);
  }
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

.full_div {
  display: flex;
  flex-direction: column;
  row-gap: 0.4vw;
  width: 100vw;
  margin-top: 0.2vw;
}

.purchases_input {
  display: flex;
  flex-direction: column;
}

.navbars {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -0.2vw;
  background: rgb(1, 63, 29);
  height: 4.8vh;
  justify-content: space-between;
  animation-name: effectTop;
  animation-duration: 1s;
}

.add_to_cart_purchase_button img {
  width: 3vw;
}

.first_column_second_row_input_field {
  display: flex;
  column-gap: 1vw;
}

.add_to_cart_purchase_button {
  position: absolute;
  right: 1vw;
  top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.3vw !important;
  height: 10vh;
  margin-left: 10vw;
  font-weight: bold;
  gap: 0.5vw;
}

.add_to_cart_purchase_button button {
  background-color: #06341b;
  width: 4vw;
  height: 3.7vw;
  border: none;
  color: rgb(5, 5, 5);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 4.4px;
  cursor: pointer;
}
.add_to_cart_purchase_button img {
  width: 100%;
}

.nav_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2vw;
  text-align: center;
  text-decoration: none;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-top: 0.2vw;
  padding-bottom: 0.3vw;
  margin: 4px 2px;
  cursor: pointer;
}

.purchase_header {
  font-size: 1.5vw;
  color: white;
  margin-left: 0.6vw;
}

.first_row_div {
  display: flex;
  flex-direction: column;
  width: 98.2vw;
  padding-right: 0.2vw;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  /* border: 1px solid white; */
}

.second_row_div {
  padding: 0.3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2vh;
  width: 98vw;
  height: 29.5vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  position: relative;
  margin-top: 0.2vw;
  /* border: 1px solid white; */
}

.third_row_div {
  display: flex;
  flex-direction: row;
  column-gap: 2vh;
  width: 98.5vw;
  height: 31.3vh;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-left: 0.75vw;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
  position: relative;
  margin-top: 0.2vw;
  /* border: 1px solid white; */
}

.first_column_second_row {
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  padding-left: 1.4vw;
  margin: 0.5vw 0vw 0vw 0.5vw;
  align-items: flex-start;
  justify-content: center;
  row-gap: 1vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 2vh;
  transition: 0.5s;
  background-color: rgba(136, 179, 154, 1);
  height: 26vh;
  width: 44vw;
  animation-name: effectLeft;
  animation-duration: 1s;
  /* border: 1px solid white; */
}

.invisible_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 97.5vw;
  height: 29vh;
}

.input_field_supershop_purchase {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px !important;
  grid-column: input;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 8vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.text_area_field_supershop_purchase {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 6.5vw;
  height: 3.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_short_select {
  display: flex;
  flex-direction: row;
}

.input_field_short_select1 {
  display: flex;
  flex-direction: row;
  margin-top: 0.4vw;
}
.input_field_short_date {
  display: flex;
  flex-direction: row;
}

.input_field_short_select select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_short_select1 select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_short_date input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 13vw;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.button_supershop {
  background-color: #06341b;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  margin-top: -0.5vw;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  position: relative;
  border-radius: 4.4px;
}

.button_supershop img {
  width: 2.4vw;
}

.button1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
}

.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
  width: 4vw;
  flex-direction: column;
}
.save_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5vw;
  font-weight: bold;
}
.brand_save_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.2vw;
  font-weight: bold;
  margin-top: 0.5vw;
}
.brand_save_button span {
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.input_field_supershop_purchase_short {
  width: 11vw !important;
  border-radius: 4.4px !important;
}

.button2:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.print_icon {
  font-size: 3vw;
}

.button_first_column_second_row {
  display: flex;
  flex-direction: row;
  column-gap: 10vw;
  margin-top: 1.7vw;
}
.button_first_column_second_row button {
  background-color: #06341b;
}

.supplier_add_button {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #06341b;
  width: 2.4vw;
  height: 1.7vw;
  margin-left: 0.5vw;
  border: none;
  color: white;
  font-size: 1.3vw;
  border-radius: 4.4px;
  cursor: pointer;
}
.vat_add_button {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #06341b;
  width: 2.4vw;
  height: 1.6vw;
  margin-left: 0.2vw;
  border: none;
  color: white;
  font-size: 1.3vw;
  border-radius: 4.4px;
  cursor: pointer;
}
.brand_add_button {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #06341b;
  width: 2.4vw;
  height: 1.6vw;
  margin-left: 0.2vw;
  border: none;
  color: white;
  font-size: 1.3vw;
  border-radius: 4.4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.save_and_delete_brand {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
}
.quantity_add_purchaes {
  width: 4.2vw !important;
  height: 1.4vw !important;
}

.Genarate_barcode_button button {
  margin-top: 1vw;
  width: 9vw;
  height: 3.2vw;
  border: none;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  font-size: 0.8vw;
  border-radius: 4.4px;
  cursor: pointer;
}

.unit_add_purchaes_opeartion {
  width: 3.5vw !important;
  margin-left: 0.2vw;
  height: 1.4vw !important;
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border: none;
  border-radius: 3px;
}

.label_field_supershop_purchase {
  box-shadow: none !important;
  display: flex;
  align-items: center;
  box-shadow: none;
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.9vw;
  padding-left: 0.3vw;
  width: 7vw;
  height: 3.2vh;
  font-weight: bold;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.label_field_supershop_purchase_image {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  margin-left: 1.1vw;
  font-size: 0.8vw;
  padding-left: 0.3vw;
  width: 6.5vw;
  height: 3.2vh;
  font-weight: bold;
}

.customer_fieldset {
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  column-gap: 1vw;
  width: 20vw;
  height: 25.5vh;
  background-color: rgba(136, 179, 154, 1);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  animation-name: effectRight;
  animation-duration: 1s;
}
.error_handle_input {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer !important;
}
.error_message {
  position: absolute;
  bottom: 0;
  font-size: 0.6vw;
  color: rgb(207, 46, 46);
}
.error_message_supllier {
  position: absolute;
  top: 1.8vw;
  left: 7vw;
  font-size: 0.7vw;
  color: rgb(207, 46, 46);
}
.error_message_paid {
  position: absolute;
  top: 1.5vw;
  left: 8.1vw;
  font-size: 0.6vw;
  color: rgb(207, 46, 46);
}
.error_message_brand_save {
  position: absolute;
  top: 1.5vw;
  left: 7vw;
  font-size: 0.9vw;
  color: rgb(207, 46, 46);
}
.bar_total_purchase input {
  box-shadow: none !important;
}

.customer_inner_div1 {
  display: flex;
  flex-direction: row;
  border-radius: 2vh;
  width: 28vw;
  height: 6vh;
  margin-left: 0.6vw;
  column-gap: 1vw;
  margin-top: 0.8vw;
}

.customer_inner_div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2vh;
  padding: 1vw;
  height: 8vh;
  margin-top: 3.5vw;
  row-gap: 0.5vw;
}

.customer_inner_div3 {
  display: flex;
  flex-direction: row;
  border-radius: 2vh;
  padding-left: 1vw;
  width: 13.6vw;
  height: 4vh;
}

.customer_inner_div4 {
  display: flex;
  flex-direction: row;
  border-radius: 2vh;
  width: 15vw;
  height: 4vh;
  background-color: white;
}

.invisible_div_sale_col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 20vw;
  height: 23vh;
  margin-left: 5vw;
  margin-right: 1vw;
  background-color: lightsteelblue;
  animation-name: effectLeft;
  animation-duration: 1s;
}

.invisible_div_sale_col2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  padding: 0.4vw;
  width: 30vw;
  height: 23vh;
  margin-left: 1vw;
  margin-right: 2vw;
  background-color: lightsteelblue;
  animation-name: effectLeft;
  animation-duration: 1s;
}
.modal_supplier {
  width: 30vw;
  height: 10vw;
}
.cart_icon_purchase {
  font-size: 3vw;
  color: rgba(29, 126, 75, 1);
}

.table_div_supershop_purchase {
  box-shadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
  border-radius: 1vh;
  margin-bottom: 1vh;
  width: 97vw;
  height: 27vh;
  background-color: rgba(136, 179, 154, 1);
  overflow-y: scroll;
}
input[type="checkbox"] {
  transform: scale(1.2); /* Adjust the scale factor to increase the size */
  -webkit-transform: scale(1.2); /* For Safari compatibility */
  
}


.barcode_check_box_purchase {
  margin-left: 1.6vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
  gap: 6px;
}

.table_supershop_purchase {
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  transition: 0.5s;
  margin-top: 0.6vw;
  margin-left: 0.8vw;
  margin-right: 0.4vw;
  margin-bottom: 0.3vw;
  width: 97vw;
  height: 30vh;
  animation-name: effectLeft;
  animation-duration: 1s;
}

.total_div_supershop_purchase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.2vw;
  padding-right: 1vw;
  margin-right: 0.5vw;
  margin-top: 0.5vw;
  column-gap: 1vw;
  width: 24vw;
  height: 26vh;
  background-color: rgba(136, 179, 154, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 2vh;
  /* border: 1px solid white; */
  transition: 0.5s;
  animation-name: effectButtom;
  animation-duration: 1s;
  position: relative;
}

.second_column_third_row_input_field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.2vw;
  padding-right: 1vw;
  margin-right: 0.5vw;
  margin-top: 0.5vw;
  column-gap: 1vw;
  width: 23vw;
  height: 26vh;
  background-color: rgba(136, 179, 154, 1);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 2vh;
  border: 1px solid white;
  transition: 0.5s;
  animation-name: effectbuttom;
  animation-duration: 1s;
  position: relative;
}

.thrid_row_bar_inside {
  display: flex;
  flex-direction: column;
  margin-top: 4.3vh;
}

.total_div_supershop_purchase label {
  width: 8vw !important;
}

.second_column_third_row_input_field label {
  width: 8vw !important;
}

.second_column_third_row_input_field input {
  width: 10vw !important;
}

.bar_inside_total_div_supershop_purchase {
  background: rgba(6, 52, 27, 1);
  width: 23vw;
  height: 4vh;
  position: absolute;
  bottom: 10.4vh;
  margin-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input_field_long {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
  position: relative;
}

.input_field_longs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
}

.input_field_longs input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 15vw;
  height: 5.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_long input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 0.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.9vw;
  background-color: #ffffff;
  width: 14vw;
  height: 3.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_long_supplier {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
  position: relative;
}
.input_field_long_supplier input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  font-size: 0.9vw;
  padding-left: 0.2vw;
  background-color: #ffffff;
  width: 14vw;
  height: 3.5vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.table_supershop_purchase th {
  position: sticky;
  top: 0;
  background: rgba(6, 52, 27, 1);
  color: #cac3c3;
  width: 100vw;
}

.table_supershop_purchase td {
  text-align: center;
}

.input_field_long label {
  box-shadow: none;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.9vw;
  padding-left: 0.7vw;
  width: 5vw;
  height: 3.2vh;
  font-weight: bold;
}

.input_field_short_long_image label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  font-size: 0.9vw;
  padding-left: 0.7vw;
  width: 10vw;
  height: 3.2vh;
  font-weight: bold;
}

.label_image {
  width: 10vw !;
}

.input_field_short_purchase {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
  position: relative;
  margin-top: 0.3vw;
}

.input_field_short_long {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.7vh;
  position: relative;
}
.input_field_short_long select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 10.5vw;
  height: 3.8vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.add_supplier_select_input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  font-size: 0.9vw;
  padding-left: 0.2vw;
  background-color: #ffffff;
  width: 11vw !important;
  height: 3.7vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_short_long_image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
}

.input_field_short_total {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
  margin-left: 38vw;
}
.third_row_third_column_purchase_operation {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  width: 15vw;
  margin: 0.4vw;
  border-radius: 1vw;
  animation-name: effectRight;
  animation-duration: 1s;
}
.input_field_short_purchase input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.2vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  padding-left: 0.3vw;
}

.input_field_supershop_purchase_long_brand {
  width: 7.9vw !important;
}
.input_field_purchase {
  display: flex;
  align-items: center;
  position: relative;
}
.vat_input_purchase_operation {
  width: 8.5vw !important;
  height: 3.4vh;
  border-radius: 4.4px;
  border: none;
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_short_purchase span {
  font-weight: bold;
}
.input_field_supershop_purchase_long_discount {
  width: 9.8vw !important;
}
.input_field_short_long span {
  font-weight: bold;
}
.input_field_purchase_first_column {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  padding: 0.6vw;
  display: flex;
  column-gap: 1vw;
  margin-left: 0.5vw;
  width: 66.6vw;
  border-radius: 1vw;
  /* border: 1px solid white; */
  border-color: white;
  position: relative;
  flex-direction: column;
  animation-name: effectLeft;
  animation-duration: 2s;
}
.purchases_input_last {
  position: absolute;
  right: 7.8vw;
  top: 0vw;
}

.input_field_short_long input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 4.4px;
  grid-column: input;
  align-self: left;
  font-size: 0.8vw;
  background-color: #ffffff;
  width: 10.5vw;
  height: 3.4vh;
  border: 0.001px solid black;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  padding-left: 0.3vw;
}

.input_field_short_long label {
  box-shadow: none;
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 7vw;
  height: 3vh;
  font-weight: bold;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

/*  */

.bar_inside_label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.8vw;
  padding-left: 0.7vw;
  margin-left: 1vw;
  width: 5vw;
  height: 3.2vh;
  font-weight: bold;
}

.error_message_supplier {
  position: absolute;
  top: 0.3vw;
  margin-top: 1.2vw;
  margin-left: 1.2vw;
  font-size: 0.6vw;
  color: rgb(207, 46, 46);
}

@keyframes effectLeft {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectTop {
  0% {
    transform: translateY(-2vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectbuttom {
  0% {
    transform: translateY(10vw);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  5% {
    visibility: hidden;
    opacity: 0.05;
    box-shadow: 2px 2px 125px 2px rgba(145, 141, 141, 0.5);
  }

  10% {
    visibility: visible;
    opacity: 0.1;
    box-shadow: 2px 2px 120px 2px rgba(145, 141, 141, 0.5);
  }

  15% {
    visibility: visible;
    opacity: 0.15;
    box-shadow: 2px 2px 110px 2px rgba(145, 141, 141, 0.5);
  }

  20% {
    visibility: visible;
    opacity: 0.2;
    box-shadow: 2px 2px 100px 2px rgba(145, 141, 141, 0.5);
  }

  25% {
    visibility: hidden;
    opacity: 0.25;
    box-shadow: 2px 2px 95px 2px rgba(145, 141, 141, 0.5);
  }

  30% {
    visibility: visible;
    opacity: 0.3;
    box-shadow: 2px 2px 90px 2px rgba(145, 141, 141, 0.5);
  }

  35% {
    visibility: visible;
    opacity: 0.35;
    box-shadow: 2px 2px 85px 2px rgba(145, 141, 141, 0.5);
  }

  40% {
    visibility: visible;
    opacity: 0.4;
    box-shadow: 2px 2px 80px 2px rgba(145, 141, 141, 0.5);
  }

  45% {
    visibility: hidden;
    opacity: 0.45;
    box-shadow: 2px 2px 75px 2px rgba(145, 141, 141, 0.5);
  }

  50% {
    visibility: visible;
    opacity: 0.5;
    box-shadow: 2px 2px 70px 2px rgba(145, 141, 141, 0.5);
  }

  55% {
    visibility: visible;
    opacity: 0.55;
    box-shadow: 2px 2px 65px 2px rgba(145, 141, 141, 0.5);
  }

  60% {
    visibility: visible;
    opacity: 0.6;
    box-shadow: 2px 2px 60px 2px rgba(145, 141, 141, 0.5);
  }

  65% {
    visibility: visible;
    opacity: 0.65;
    box-shadow: 2px 2px 55px 2px rgba(145, 141, 141, 0.5);
  }

  70% {
    visibility: hidden;
    opacity: 0.7;
    box-shadow: 2px 2px 50px 2px rgba(145, 141, 141, 0.5);
  }

  75% {
    visibility: visible;
    opacity: 0.75;
    box-shadow: 2px 2px 45px 2px rgba(145, 141, 141, 0.5);
  }

  80% {
    visibility: visible;
    opacity: 0.8;
    box-shadow: 2px 2px 40px 2px rgba(145, 141, 141, 0.5);
  }

  85% {
    visibility: visible;
    opacity: 0.85;
    box-shadow: 2px 2px 15px 2px rgba(145, 141, 141, 0.5);
  }

  90% {
    visibility: visible;
    opacity: 0.9;
    box-shadow: 2px 2px 10px 2px rgba(145, 141, 141, 0.5);
  }

  95% {
    visibility: visible;
    opacity: 0.95;
    box-shadow: 2px 2px 5px 2px rgba(145, 141, 141, 0.5);
  }

  100% {
    visibility: visible;
    opacity: 1;
    box-shadow: 2px 2px 4px 2px rgba(145, 141, 141, 0.5);
  }
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-header {
  color: rgba(0, 0, 0, 0.88);
  background: rgba(187, 210, 198, 1);
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
  padding: 0;
  border-bottom: none;
  margin-left: 0.3vw;
}

.container_permanent_supplier_supershop {
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
}
:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-content {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  width: auto;
  border-radius: 2vh;
  background-color: rgba(187, 210, 198, 1);
}
.input_field_permanent_supplier_supershop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.8vw;
  position: relative;
}

.container_button_permanent_customer_supershop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_button_permanent_customer_supershop span {
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-size: 1.2vw;
  font-weight: bold;
  margin-top: 0.5vw;
}

.input_field_permanent_supplier_supershop input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 0.9vw;
  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.5vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.save-and-delete-button-supplier {
  display: flex;
  column-gap: 30px;
}
.activeRow {
  background-color: #1b7542;
  color: #fff;
}
.input_field_permanent_supplier_supershop label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px; */
  color: #000;
  font-size: 0.8vw;
  padding-left: 0.3vw;
  width: 7vw;
  height: 3.2vh;
  font-weight: bold;
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
}

.first_row_div_permanent_supplier_supershop {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6vw;
}
.second_row_modal {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  border-radius: 2vh;
  height: 15vw;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.searchbar_supplier {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgba(136, 179, 154, 1);
  border-radius: 2vh;
  padding: 10px 20px;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.searchbar_buttton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchbar_buttton button {
  background-color: rgba(6, 52, 27, 1) !important;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4.4px;
  padding: 4px 20px;
  border: none;
}
.input_field_brand_supershop {
  display: flex;
}
.input_field_brand_supershop label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-size: 0.8vw;
  padding-left: 0.3vw;
  width: 7vw;
  height: 3.2vh;
  font-weight: bold;
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
}
.input_field_brand_supershop input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 0.9vw;
  padding-left: 0.3vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.5vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.table_div_modal {
 
  overflow-y: scroll;
  height: 100%;
  margin-left: 1vw;
  margin-right: .5vw;
  height: 14vw;
}
.table_div_modal table td {
  font-size: 0.9vw;
}
.container_search_permanent_supplier_supershop {
  /* box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821); */
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  padding: 1vw;
  margin: 0.3vw;
  row-gap: 0.5vw;
}

.container_separate_permanent_supplier_supershop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 2vw;
}

.search_permanent_customer_supershop {
  /* box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821); */
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.5vw;
}

.container_view_permanent_customer_supershop {
  display: flex;
  flex-direction: column;
}

.container_view_money_permanent_customer_supershop {
  display: flex;
  flex-direction: row;
}

/* second row div */

.table_wrapper_permanent_customer_supershop {
  max-height: 500px;
  overflow-y: scroll;
  box-shadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
  border-radius: 2vh;
  transition: 0.5s;
  height: 14vw;
  padding-top: 0vh;
}

.third_row_div_permanent_customer_supershop {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 5vw;
  padding-right: 1vw;
}
.table_div_modal {
  scrollbar-color: rgba(136, 179, 154, 1) rgba(187, 210, 198, 1);
}

.table_div_modal::-webkit-scrollbar-track {
  background: rgb(193, 219, 206);
}

.conatiner-support-ticket {
  background-color: #88b39a;
  width: 97%;
  height: 89vh;
  margin: 20px;
  border-radius: 10px;
  padding: 15px;
}
.my-support-ticket-table {
  overflow-y: scroll;
  scrollbar-color: #88b39a #bbd2c6;
  scroll-behavior: smooth;
  box-shadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
  border-radius: 1vh;
  transition: 0.5s;
  padding-top: 0vh;
  padding-left: 0.5vw;
  column-gap: 1vw;
  width: 95vw;
  height: 85vh;
  animation-name: effectLeft;
  animation-duration: 1s;
  background-color: #bbd2c6;
}
.answer-section {
  height: 10vh;
  margin-top: 10px;
}
/* .my-support-ticket-table table{
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
} */
.my-support-ticket-table table th {
  position: sticky;
  top: 0;
  background: rgba(6, 52, 27, 1);
  color: #cac3c3;
  width: 100vw;
  padding: 3px;
}
.my-support-ticket-table table td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 0;
  font-size: 1vw;

  padding: 3px;
}

.first_row_div_view_ticket {
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 6vw;
  row-gap: 10px;
}
.question_title {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: rgba(136, 179, 154, 1);
  padding: 10px;
  width: 25vw;
  border-radius: 10px;
  font-size: 1rem;
}
.answer-section {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: rgb(100, 156, 123);
  padding: 10px;
  width: 30vw;
  border-radius: 10px;
  font-size: 1rem;
  height: auto;
  margin-left: 4vw;
  margin-top: -1px;
}
.container_view_ticket {
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  row-gap: 1vw;

  height: auto;
  padding-bottom: 10px;
  padding-left: 10px;
  max-height: 70vh;
}
.first_row_div_view_ticket {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 0 !important;
}

.first_row_div_view_ticket::-webkit-scrollbar {
  display: none;
}
.custom-modal {
  max-width: 38% !important;

  /* Set your desired maximum width */
}
.view_ticket_reply_input textarea {
  height: 7vh;
  width: 87%;
  overflow-y: auto;
  resize: none;
  font-size: 1rem;
  padding: 5px;
  border: none;
  outline-color: #337a50 !important;
  border-radius: 5px;
}
.view_ticket_reply_input textarea::-webkit-scrollbar {
  display: none;
}
.view_ticket_reply_input textarea:active {
  outline-color: #337a50 !important;
}
.view_ticket_reply_input {
  display: flex;
  text-align: center;
  column-gap: 10px;
}
.view_ticket_reply_input button {
  width: 10%;
  font-size: 1.3rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #337a50;
}
.merinasoft_reply_section {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}

.logo_view_ticket {
  border: 1px solid gray;
  border-radius: 50%;
  width: 2.2vw;
  height: 2.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 15px;
}
.logo_view_ticket img {
  width: 100%;
}

.pending {
  background-color: rgb(161, 161, 3);
  color: #fffafa;
  cursor: pointer;
}
.open {
  background-color: rgb(5, 150, 5);
  color: #fffafa;
  cursor: pointer;
}
.date_time_message{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
.cash_Holder {
  background-color: var(--bodyColor);
  height: 94vh;
}
.cash_container {
  display: flex;
  flex-direction: column;
  gap: 1.3vh 0.2vw;
}

.card {
  background-color: var(--bg);
  margin: 0 0.7vw;
  padding: 1vw;
  border-radius: 1vh;
  box-shadow: var(--largeCardShadow);
}
.cash_header {
  margin-top: 2.2vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  height: 9vh;
  box-shadow: var(--largeCardShadow);
}
.cash_header > div:nth-child(1),
.cash_header > div:nth-child(2) {
  background-color: var(--cardBg);
  height: 7vh;
  border-radius: 1vw;
  padding: 1vw;
  animation-name: effectTop;
  animation-duration: 1s;
}
.dateDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.dateLabelDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  font-weight: bold;
  height: 2.5vh;
  width: 5vw;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.dateInput {
  font-size: 0.9vw;
  box-shadow: var(--labelShadow);
  width: 9vw;
  height: 3vh;
  border: none;
  border-radius: 0.3vw;
  padding: 0.2vw;
}

.dateBtnsearch {
  margin-left: 0.8vw;
}

.dateButton {
  margin-left: 1vw;
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
  padding: 0.1vw;
}
.searchIcon {
  color: white;
  font-size: 1.1vw;
  background-color: transparent;
}

.fromToDateDiv {
  display: flex;
  align-items: center;
  gap: 1.2vw;
}

.showAllDiv {
  margin-right: 4vw;
  animation-name: effectTop;
  animation-duration: 1s;
}

/* //main div  starts from here============================*/

.cash_tableDiv {
  background-color: var(--cardBg);
  height: 35vh;
  border-radius: 0.5vw;
  box-shadow: var(--smallCardShadow);
  overflow: scroll;
  overflow-x: hidden;
  animation: effectLeft;
  animation-duration: 1s;
}
/* .cash_table {
}

.cash_table_thead {
}
.cash_table_head_tr {
} */

.cash_table_head_th {
  background-color: var(--btnColor);
  color: white;
  font-size: 1vw;
}

.cash_table_Body_tr {
  background-color: #ffffff;
}
.cash_table_Body_td {
  color: rgb(0, 0, 0);
  font-size: 0.9vw;
  border: 0.1vw solid var(--bg);
}

.cash_tr {
  background-color: #f0f0f0;
  font-size: 2vw;
}
.inAmount {
  background-color: lightgreen;
}
.outAmount {
  background-color: #b7cdc2;
}
.cash_tr:hover > td {
  background-color: #808080;
}

.tab_selected > .cash_table_Body_td {
  background-color: #318153 !important;
  color: white;
}

/* //footer div  starts from here==================================*/

.cash_Footer {
  height: 35vh;
  display: flex;
  align-items: center;
}

.totalDiv {
  background-color: var(--cardBg);
  height: 26vh;
  width: 35vw;
  margin-right: 2vw;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  box-shadow: var(--smallCardShadow);
  animation-name: effectButtom;
  animation-duration: 1s;
}

.Amount {
  display: flex;
  gap: 1.3vw;
  align-items: center;
}

.amountebelDiv {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.9vw;
  height: 3vh;
  font-weight: bold;
  width: 9vw;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.amountInput {
  font-size: 1vw;
  box-shadow: var(--labelShadow);
  width: 14vw;
  height: 3.5vh;
  border: none;
  border-radius: 0.3vw;
  padding: 0.3vw;
}

.cashOperationDiv {
  height: 20vh;
  width: 25vw;
  margin: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chasOperationBtn {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 8vw;
  height: 5vh;
  font-size: 1vw;
  padding: 0.2vw;
  font-size: 1vw;
  color: white;

  animation-name: effectButtom;
  animation-duration: 1s;
}

.excelExportBtn {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 6vw;
  height: 5vh;
  padding: 0.2vw;
  font-size: 1.5vw;
  animation-name: effectButtom;
  animation-duration: 1s;
}
.xlIcon {
  color: white;
  background-color: transparent;
}

/* /=======================/modal */

.operationModalContent {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bbd2c6;
}
.showAll_button {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
}

.divForALlbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonText {
  text-align: center;
  font-size: 0.8vw;
  height: 0.4vh;
  color: #06341b;
  animation-name: effectButtom;
  animation-duration: 1s;
}

.buttonTextCash {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  height: 3vh;
  font-weight: bold;
  width: 9vw;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

/*================ animation ==========================*/
@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectButtom {
  0% {
    transform: translateY(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes transition_from_left_to_right {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

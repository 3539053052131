:root {
  --bodyColor: #bbd2c6;
  --bg: #88b39a;
  --cardBg: #bbd2c6;
  --btnColor: #06341b;
  --largeCardShadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  --smallCardShadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  --labelShadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  --buttonShadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
}

.full_div_quotation {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
  width: 100vw;
  height: 93.3vh;
  overflow: hidden;
  background-color: var(--bodyColor);
}
.full_div_quotation ::-webkit-scrollbar {
  width: 0.4vw;
  background-color: var(--bg);
  border-radius: 0.3vw;
}
.full_div_quotation ::-webkit-scrollbar-thumb {
  width: 0.5vw;
  background-color: rgb(233, 232, 232);
}
.conatiner_div_quotation {
  display: grid;
  grid-template-columns: 6fr 2.5fr;
  align-items: center;
  justify-content: center;
}

.first_rov_div_quotation {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.7vw;
  margin: 0.8vw;
  column-gap: 1vw;
  width: 70vw;
  height: 90vh;
  background-color: var(--bg);
}
.second_row_div_quotation {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  /* padding-left: 1vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-left: 1vw;
  padding-top: 1.5vh;
  column-gap: 1vw;
  width: 26vw;
  height: 90vh;
  background-color: var(--bg);
}

.quotion_search_row_div2 {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  /* padding-left: 1vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1vw;
  margin-bottom: 1vw;
  padding-top: 1vh;
  column-gap: 1vw;
  width: 23vw;
  height: 30vh;
  animation-name: effectTop;
  animation-duration: 1s;
  background-color: var(--cardBg);
}

.container_sale_summary_quotation_full_div {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  /* padding-left: 1vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1vw;
  /* margin-bottom: 2vw; */
  padding-top: 1vh;
  column-gap: 1vw;
  width: 23vw;
  height: 55vh;
  animation-name: effectButtom;
  animation-duration: 1s;
  background-color: var(--cardBg);
}

.input_field_quotation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.6vw;
}

/* Input, Select, Button, Label Field Start*/
.input_field_quotation label {
  /* display: inline-block; */
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  font-size: 0.7vw;
  padding-left: 0.7vw;
  width: 7vw;
  height: 3.2vh;
  font-weight: bold;
}
.input_field_quotation input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  grid-column: input;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 7vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_quotation select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  grid-column: select;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 7vw;
  height: 3.2vh;
  border-color: #cac3c3;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.input_field_quotation button {
  padding: 5px 5px;
  font-size: 0.8vw;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vh;
  box-shadow: 0 5px #999;
  width: 6vw;
  height: 3.2vh;
}

/* Input, Select, Button, Label Field  End*/
.quotation_search_row_div1 {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.7vw;
  /* margin: 0.8vw; */
  column-gap: 1vw;
  width: 68vw;
  height: 20vh;
  animation-name: effectTop;
  animation-duration: 1s;
  background-color: var(--cardBg);
}
/* Custom size */
.input_field_quotation .input_field_quotation_lg {
  width: 4vw;
  height: 3.2vh;
}
.input_field_quotation .input_field_quotation_sm {
  width: 3vw;
  height: 3.2vh;
}
.input_field_quotation .input_field_quotation_eq {
  width: 3.5vw;
  height: 3.2vh;
}
.input_field_quotation_percusto {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5vw;
}
.input_field_quotation_percusto .quotation_input_checkbox {
  width: 1vw;
  height: 2vh;
  margin-left: 1vw;
}

.quotation_font_size {
  font-size: 1vw;
  font-weight: bold;
}
.quotation_font_size-sm {
  font-size: 0.8vw;
  font-weight: bold;
}
/* Custom size End */
.container_table_quotation {
  box-shadow: 0 4px 30px 0 rgba(167, 169, 170, 0.821);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  padding: 0.7vw;
  /* margin: 0.8vw; */
  column-gap: 1vw;
  width: 68vw;
  height: 50vh;
  animation-name: effectLeft;
  animation-duration: 1s;
  background-color: var(--cardBg);
  overflow: scroll;
  overflow-x: hidden;
}
.quotation_table_wrapper {
  max-height: 500px;
  overflow: scroll;
  box-shadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
  border-radius: 2vh;
  transition: 0.5s;
  padding-top: 0vh;
  padding-left: 0.5vw;
  column-gap: 1vw;
  width: 100vw;
  height: 44vh;
  margin-bottom: 1vw;
}

th {
  position: sticky;
  top: 0;
  color: #cac3c3;
  width: 100vw;
  font-size: 1vw;
  border: none;
}
.headerTh {
  background-color: var(--btnColor);
}
.tableTd {
  background-color: #ffffff;
  border: 0.1vw solid gray;
}
td {
  text-align: center;
  font-size: 0.8vw;
}
/* .tableBody {
  background-color: #04aa6d;
  height: 38vh;
  overflow: scroll;
} */
.row:focus {
  outline: none;
  background: green;
}

.input_field_quotation .custom_label_quotation {
  width: 8.5vw;
}

.container_sale_summary_quotation {
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.container_sale_summary_quotation_button button {
  width: 7vw;
  height: 4.5vh;
  margin-left: 7vw;
}

/* Third Row div Design */
.cotainer_view_quotation {
  background-color: var(--cardBg);
  padding: 0.5vw;
  border-radius: 1vw;
  margin: 0 0.2vw;
  animation-name: effectButtom;
  animation-duration: 1s;
}

.input_field_quotation_custom {
  margin-bottom: 0.5vw;
}
.input_field_quotation_custom label {
  box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  border-radius: 3px;
  grid-column: label;
  align-self: right;
  /* font-size: 1vw; */
  font-size: 0.7vw;
  padding-left: 0.7vw;
  width: 12vw;
  height: 4vh;
  font-weight: bold;
  padding-right: 2vw;
}

.input_field_quotation_custom input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  grid-column: input;
  align-self: left;
  font-size: 0.7vw;
  background-color: #ffffff;
  width: 58vw;
  height: 6vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.cotainer_view_quotation_select {
  display: flex;
  flex-direction: row;
  padding-top: 0.8vh;
}

/* /===========/animation: */

@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectbuttom {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  5% {
    visibility: hidden;
    opacity: 0.05;
    box-shadow: 2px 2px 125px 2px rgba(145, 141, 141, 0.5);
  }
  10% {
    visibility: visible;
    opacity: 0.1;
    box-shadow: 2px 2px 120px 2px rgba(145, 141, 141, 0.5);
  }
  15% {
    visibility: visible;
    opacity: 0.15;
    box-shadow: 2px 2px 110px 2px rgba(145, 141, 141, 0.5);
  }
  20% {
    visibility: visible;
    opacity: 0.2;
    box-shadow: 2px 2px 100px 2px rgba(145, 141, 141, 0.5);
  }

  25% {
    visibility: hidden;
    opacity: 0.25;
    box-shadow: 2px 2px 95px 2px rgba(145, 141, 141, 0.5);
  }
  30% {
    visibility: visible;
    opacity: 0.3;
    box-shadow: 2px 2px 90px 2px rgba(145, 141, 141, 0.5);
  }
  35% {
    visibility: visible;
    opacity: 0.35;
    box-shadow: 2px 2px 85px 2px rgba(145, 141, 141, 0.5);
  }
  40% {
    visibility: visible;
    opacity: 0.4;
    box-shadow: 2px 2px 80px 2px rgba(145, 141, 141, 0.5);
  }
  45% {
    visibility: hidden;
    opacity: 0.45;
    box-shadow: 2px 2px 75px 2px rgba(145, 141, 141, 0.5);
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    box-shadow: 2px 2px 70px 2px rgba(145, 141, 141, 0.5);
  }
  55% {
    visibility: visible;
    opacity: 0.55;
    box-shadow: 2px 2px 65px 2px rgba(145, 141, 141, 0.5);
  }
  60% {
    visibility: visible;
    opacity: 0.6;
    box-shadow: 2px 2px 60px 2px rgba(145, 141, 141, 0.5);
  }
  65% {
    visibility: visible;
    opacity: 0.65;
    box-shadow: 2px 2px 55px 2px rgba(145, 141, 141, 0.5);
  }
  70% {
    visibility: hidden;
    opacity: 0.7;
    box-shadow: 2px 2px 50px 2px rgba(145, 141, 141, 0.5);
  }
  75% {
    visibility: visible;
    opacity: 0.75;
    box-shadow: 2px 2px 45px 2px rgba(145, 141, 141, 0.5);
  }
  80% {
    visibility: visible;
    opacity: 0.8;
    box-shadow: 2px 2px 40px 2px rgba(145, 141, 141, 0.5);
  }
  85% {
    visibility: visible;
    opacity: 0.85;
    box-shadow: 2px 2px 15px 2px rgba(145, 141, 141, 0.5);
  }
  90% {
    visibility: visible;
    opacity: 0.9;
    box-shadow: 2px 2px 10px 2px rgba(145, 141, 141, 0.5);
  }
  95% {
    visibility: visible;
    opacity: 0.95;
    box-shadow: 2px 2px 5px 2px rgba(145, 141, 141, 0.5);
  }
  100% {
    visibility: visible;
    opacity: 1;
    box-shadow: 2px 2px 4px 2px rgba(145, 141, 141, 0.5);
  }
}

@keyframes transition_from_left_to_right {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.nav{
    width: 100vw;
}

.header{
    font-size: 1.5vw;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
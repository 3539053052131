.full_div_supershop_expense_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 1vh;
  width: 100vw;
  height: 90vh;
  /* margin: auto; */
  overflow: hidden;
  background-color: #bbd2c6;
}

.first_row_div_supershop_expense_input {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  background-color: #88b39a;
  border-radius: 2vh;
  transition: 0.5s;
  padding-left: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 2vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 2vw;
  column-gap: 10vw;
  width: 96vw;
  height: 50vh;
  animation-name: transition_from_left_to_right;
  animation-duration: 1s;
}

.input_field_supershop_expense_input label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-size: 0.9vw;
  padding-left: 0.3vw;
  width: 8vw;
  height: 3.2vh;
  font-weight: bold;
}

.input_field_supershop_expense_input select {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 0.8vw;
  background-color: #ffffff;
  padding-left: 0.2vw;
  width: 12vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.input_field_supershop_expense_input input {
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 0.8vw;
  background-color: #ffffff;
  padding-left: 0.2vw;
  width: 12vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.container_button_supershop_expance_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_button_supershop_expance_input button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;
  font-size: 2vw;
  color: #e9e8e8;
  padding-left: 0.15vw;
  background-color: #043a1a;
  cursor: pointer;
  border: none;
  border-radius: 1vw;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.container_button_supershop_expance_input span {
  font-size: 1vw;
  font-weight: bold;
  text-align: center;
  padding-top: 0.5vh;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.error_message_expence_input{
  position: absolute;
  top: 0.6vw;
  margin-top: 1vw;
  margin-left: 2vw;
  font-size: .6vw;
  color: rgb(207, 46, 46);
  }

.container_search_column1_supershop_expense_input {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  background-color: #bbd2c6;
  border-radius: 2vh;
  transition: 0.5s;
  padding-left: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10vw;
  margin-top: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 2vw;
  column-gap: 1vw;
  width: 30vw;
  height: 30vh;
  animation-name: transition_from_left_to_right;
  animation-duration: 1s;
}
.container_search_column2_supershop_expense_input {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  background-color: #bbd2c6;
  border-radius: 2vh;
  transition: 0.5s;
  /* padding-left: 1vw; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  /* padding-left: 2vw; */
  column-gap: 3vw;
  width: 30vw;
  height: 30vh;
  animation-name: transition_from_left_to_right;
  animation-duration: 1s;
}
.input_field_supershop_expense_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vw;
  position: relative;
}

/* animatin */

@keyframes fadeIn {
  5% {
    visibility: hidden;
    opacity: 0.05;
    box-shadow: 2px 2px 125px 2px rgba(145, 141, 141, 0.5);
  }
  10% {
    visibility: visible;
    opacity: 0.1;
    box-shadow: 2px 2px 120px 2px rgba(145, 141, 141, 0.5);
  }
  15% {
    visibility: visible;
    opacity: 0.15;
    box-shadow: 2px 2px 110px 2px rgba(145, 141, 141, 0.5);
  }
  20% {
    visibility: visible;
    opacity: 0.2;
    box-shadow: 2px 2px 100px 2px rgba(145, 141, 141, 0.5);
  }

  25% {
    visibility: hidden;
    opacity: 0.25;
    box-shadow: 2px 2px 95px 2px rgba(145, 141, 141, 0.5);
  }
  30% {
    visibility: visible;
    opacity: 0.3;
    box-shadow: 2px 2px 90px 2px rgba(145, 141, 141, 0.5);
  }
  35% {
    visibility: visible;
    opacity: 0.35;
    box-shadow: 2px 2px 85px 2px rgba(145, 141, 141, 0.5);
  }
  40% {
    visibility: visible;
    opacity: 0.4;
    box-shadow: 2px 2px 80px 2px rgba(145, 141, 141, 0.5);
  }
  45% {
    visibility: hidden;
    opacity: 0.45;
    box-shadow: 2px 2px 75px 2px rgba(145, 141, 141, 0.5);
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    box-shadow: 2px 2px 70px 2px rgba(145, 141, 141, 0.5);
  }
  55% {
    visibility: visible;
    opacity: 0.55;
    box-shadow: 2px 2px 65px 2px rgba(145, 141, 141, 0.5);
  }
  60% {
    visibility: visible;
    opacity: 0.6;
    box-shadow: 2px 2px 60px 2px rgba(145, 141, 141, 0.5);
  }
  65% {
    visibility: visible;
    opacity: 0.65;
    box-shadow: 2px 2px 55px 2px rgba(145, 141, 141, 0.5);
  }
  70% {
    visibility: hidden;
    opacity: 0.7;
    box-shadow: 2px 2px 50px 2px rgba(145, 141, 141, 0.5);
  }
  75% {
    visibility: visible;
    opacity: 0.75;
    box-shadow: 2px 2px 45px 2px rgba(145, 141, 141, 0.5);
  }
  80% {
    visibility: visible;
    opacity: 0.8;
    box-shadow: 2px 2px 40px 2px rgba(145, 141, 141, 0.5);
  }
  85% {
    visibility: visible;
    opacity: 0.85;
    box-shadow: 2px 2px 15px 2px rgba(145, 141, 141, 0.5);
  }
  90% {
    visibility: visible;
    opacity: 0.9;
    box-shadow: 2px 2px 10px 2px rgba(145, 141, 141, 0.5);
  }
  95% {
    visibility: visible;
    opacity: 0.95;
    box-shadow: 2px 2px 5px 2px rgba(145, 141, 141, 0.5);
  }
  100% {
    visibility: visible;
    opacity: 1;
    box-shadow: 2px 2px 4px 2px rgba(145, 141, 141, 0.5);
  }
}

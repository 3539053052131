.pieChartBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Pi_charts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.options {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
  align-items: flex-start;
  flex-wrap: wrap;
}
.option {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 0.8vw;
}
.dot {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
}

.pieChartBox .recharts-tooltip-item {
  /* Target the tooltip content specifically */
  color: white !important; /* Set the text color to white */
}

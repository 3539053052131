/* Invoice.css */

.invoice {
    width: 100%;
    max-width: 800px;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid #ccc;
    height: 97vh;
    display: flex;
    flex-direction: column;

    /* font-family: 'Arial', sans-serif; */
}
.invoice-bill {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
    text-align: center;
    font-size: 2vw;
}

.headers {
    display: flex;
    display: row;
    text-align: left;
    position: relative;
}

.logo {
    width: 15vw;
    height: 11vh;
}
.row{
    display: flex;
    
}
.company-info {
    flex: 1;
    position: absolute;
    right: 6vw;
    display: flex;
    flex-direction: column;
    row-gap: 1vw;
    margin-top: 1vw;
    
}

.company-name {
    font-size: 20px;
    font-weight: bold;
}

.company-address {
    font-size: 14px;
}

.billing-info {
    display: flex;
    flex-direction: column;
    position: relative;
}
.customer-invoice {
    display: flex;
    flex-direction: row;
    column-gap: 40vw;
    margin-left: 2vw;
}
.supplier-info{
    font-size: 2vw;

}
.Supplier-invoice{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: normal !important;
}

.invoice-details {
    font-size: 14px;
}
.supplier-invoice-details{
    font-size: 2vw;
}


.table2 {
    width: 30%;
    display: grid;
    grid-template-columns: auto auto ;
   
    position: absolute;
    
    left: 75.3%;
    top:110%;
   
}

.body {
    display: flex;
    flex-direction: column;
    padding-top: 2vw;
}
.table {
    display: flex;
    flex-direction: column;
    row-gap: 2vw;
    position: relative;
    /* margin-top: 10vh; */
}

.product-table {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
   
}
.Purchase-table{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto auto ;
}

.product-table th {
    font-size: 1vw;
    border: 1.5px solid #000;
    padding: 10px;
    text-align: center;
    background-color: #fff;
    color: #000;
}
.product-table td {
    font-size: 0.9vw;
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    background-color: #fff;
    color: #000;
}
.word{
    margin-top: 1vw;
    font-size: 1.9vw;
}
.total {
    font-display: flex;
    flex-direction: column;
    row-gap: 2vw;
    margin: 2vw;
    margin-top: 4vw;
   
}

.footer {
    display: flex;
    flex-direction: column;
    
    margin-top: 18vh;
    row-gap: 2vw;
}
.last_part {
    display: flex;
    flex-direction: column;
    margin: 2vw;
}
.horizontal-line {
    border-bottom: 1px solid #000;
}
.horizontal-line1 {
    border-bottom: 2px solid #000;
    margin-top: 5vw;
}

.ceo-signature {
    font-style: italic;

    display: flex;
    flex-direction: row;
    column-gap: 35vw;
    padding: 2vw;
    /* margin-right: 4vw; */
}
.total_row {
    display: grid;
    grid-template-columns: auto auto;
    width: 20vw;
    margin-top: 1vw;
}
.div {
    border: 1px solid black;
    padding: 2px;
}
.div1{
  border: 1px solid black;
  padding: 2px;
  width: 10vw;
}
.barcode{
    display: flex;
    align-items: center;
    justify-content: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
:root {
  --bodyColor: #bbd2c6;
  --bg: #88b39a;
  --cardBg: #bbd2c6;
  --btnColor: #06341b;
  --largeCardShadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  --smallCardShadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  --labelShadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5);
  --buttonShadow: 0 4px 30px 0 rgba(25, 24, 24, 0.2);
}

.product_container {
  background-color: var(--bodyColor);
  /* height: 94vh; */
}
.product_container ::-webkit-scrollbar {
  width: 0.4vw;
  background-color: var(--bg);
  border-radius: 0.3vw;
}
.product_container ::-webkit-scrollbar-thumb {
  width: 0.5vw;
  background-color: rgb(233, 232, 232);
}
.card {
  padding: 0.1px;
  border-radius: 0.2vw;
}

.productIconDiv {
  display: flex;
  align-items: center;
  margin: 0.1vw;
}
.reload_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
  margin-right: 1.2vw;
  background-color: transparent;
  border: none;
  color: #04aa6d;
  background-color: #efefef;
  padding: 0.3vw;
  border-radius: 0.2vw;
}
.arrow_icons {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5vw;
  background-color: transparent;
  border: none;
  color: #04aa6d;
  margin: 0 0.5vw;
  background-color: #efefef;
  padding: 0.3vw;
  border-radius: 0.2vw;
}

/* ===========//categories start from here ===========================================*/

.Categories_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 17.5vh;
  background-color: var(--bg);
  width: 98.5%;
  border-radius: 1vw;
  margin: 0.1vh auto;
  margin-top: 1vh;
  box-shadow: var(--largeCardShadow);
  /* padding: 0 1vw; */
}

.sanitory_div {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}
.text_h3 {
  font-size: 1.1vw;
  color: rgb(0, 0, 0);
  height: 3vh;
  animation-name: effectTop;
  animation-duration: 1.2s;
}
.sanitory_card {
  border-radius: 1vw;
  animation-name: effectTop;
  animation-duration: 1.2s;
  background-color: var(--cardBg);
  padding: 0.5vw;
  box-shadow: var(--smallCardShadow);
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  margin-top: 0.1vh;
}

.sanitary_select {
  border: none;
  padding: 0.3vw;
  width: 30vw;
  height: 10vh;
  background-color: transparent;
  border-radius: 1vw;
  outline: none;
  font-size: 1vw;
  font-weight: bold;
}

.sanitary_select > option {
  margin: 0.3vw;
  margin-left: 1vw;
}
.sanitary_select option:checked {
  background-color: transparent;
}
.sanitory_text {
  background-color: var(--cardBg);
  margin: 1vw;
  height: 15vh;
  width: 30vw;
  /* margin-top: 4.2vh; */
  border-radius: 0.8vw;
  padding: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-name: effectTop;
  animation-duration: 1.2s;
  box-shadow: var(--smallCardShadow);
}

.input_div {
  margin: 0.2vh 0.1vw;
  padding: 0.2vw;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_div > label {
  margin-right: 0.4vw;
}

.label {
  width: 8vw;
  font-size: 0.9vw;
  /* box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5); */
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-weight: bold;
  margin-right: 1vw;
}

.nameInput {
  width: 12vw;
  height: 3.2vh;
  border-radius: 0.2vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
  background-color: white;
  font-size: 0.9vw;
  text-align: center;
}

.codeInput {
  width: 12vw;
  height: 3.2vh;
  border-radius: 0.2vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
  font-size: 0.9vw;
  text-align: center;
}

.sanitory_btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.sanity_icon {
  font-size: 1.4vw;
  margin: 0 2.6vw;
  padding: 0.3vw 0.3vw 0px 0.3vw;
  background-color: white;
  border: none;
}
.refresh {
  color: #274539;
  background-color: transparent;
}
.add {
  color: var(--btnColor);
  background-color: transparent;
}
.delete {
  color: rgb(87, 0, 0);
  background-color: transparent;
}

/*=======================inventory_product_div====================================================== */

.inventory_product_div {
  display: flex;
  justify-content: space-between;
  column-gap: 2vw;
  height: 39vh;
  width: 98.5vw;
  margin: 0.6vh auto;
  padding: 1vw;
  border-radius: 0.5vw;
  background-color: var(--bg);
  align-items: center;
  box-shadow: var(--largeCardShadow);
  margin-top: 0.8vh;
}

/* /====================/table container starts from here */
.product_table {
  width: 50%;
  padding: 0.2vw;
  border-radius: 1vw;
  overflow: auto;
  animation-name: effectLeft;
  animation-duration: 1.2s;
  background-color: var(--cardBg);
  /* margin-left: 0.4vw; */
  height: 37vh;
  /* margin-left: 1vw; */
  box-shadow: var(--smallCardShadow);
  overflow: hidden;
}

.SearchDiv {
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding: 0px 10px;
}
.Header_text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
}
.types-h3 {
  color: rgb(0, 0, 0);
  margin-bottom: 1vh;
}

.search_input_div {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_input {
  background-color: var(--bg);
  border: none;
  height: 3.7vh;
  width: 26vw;
  margin: 0.5vh;
  border-radius: 0.5vw;
  padding-left: .5vw;
}
.search_input:focus {
  border-color: transparent;
  outline: none;
}
.searchBtn {
  background-color: var(--btnColor);
  border: none;
  color: white;
  width: 6vw;
  height: 3.7vh;
  font-size: .9vw;
  border-radius: 0.5vw;
  margin-left: 0.5vw;

  box-shadow: var(--buttonShadow);
}
.product_table_container {
  overflow-y: scroll;
  transition: 0.5s;
  height: 32vh;
  width: 100%;
}

.heading_row {
  position: sticky;
  top: 0;
}
.table th {
  text-align: center;
  margin: 0;
}
.tables {
  width: 100%;
}

.table td {
  font-size: 1vw;
  text-align: center;
  background-color: #efefef;
  font-weight: bold;
}

.t_data {
  /* resize: horizontal; */
  overflow: auto;
  font-size: 0.9vw;

  color: white;
  padding: 0.2vw;
  background-color: #06341b;
}

.tbody {
  padding: 1vw;
  background-color: #e6ece9;
}

.addProduct_tr:hover {
  background-color: #808080;
}
.addProduct_td {
  font-size: 0.9vw;
  overflow-wrap: normal;
  color: #000000;
}
.tab_selected > .addProduct_td {
  background-color: #318153;
  color: white;
}
.edit_delete_btn_div {
  display: flex;
  font-size: 1vw;
  align-items: center;
  justify-content: center;
}
.row_edit_btn {
  font-size: 1.1vw;
  margin-right: 0.1vw;
  padding: 0.2vw 0.2vw 0px 0.2vw;
  border: none;
  color: #04aa6d;
  background-color: transparent;
}

.row_delete_btn {
  font-size: 1.1vw;
  margin-left: 0.1vw;
  padding: 0.2vw 0.2vw 0px 0.2vw;
  border: none;
  color: #ff0303;
  background-color: transparent;
}

/* /===================/input container starts from here */
.product_input_container {
  background-color: var(--cardBg);
  border-radius: 1vw;
  animation-name: effectRight;
  animation-duration: 1.2s;
  height: 37vh;
  padding: 1vw;
  /* margin-right: 1.5vw; */
  box-shadow: var(--smallCardShadow);
}

.input_div {
  display: flex;
  align-items: center;
}

.left_div {
  margin-right: 0.8vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_div {
  margin-right: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image_input_div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4vw;
}

.pLabel {
  font-size: 0.9vw;
  font-weight: bold;
  margin-right: 0.8vw;
  text-align: left;
  width: 7vw;
  /* box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5); */
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.productInput {
  width: 12vw;
  height: 3.2vh;
  font-size: 1vw;
  border-radius: 0.2vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  border: none;
  background-color: white;

  text-align: center;
}
.forSomeMergine {
  margin-bottom: 1vh;
}
.textareaInput {
  margin-top: -3vh;
}
.imageInput {
  font-size: 1vw;
}

.uploadBtn {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.4vw;
  box-shadow: var(--buttonShadow);
  width: 7vw;
  font-size: 0.9vw;
  color: white;
  margin-top: 0.3vh;
  /* margin-left: 1vw; */
  margin-left: 3vw;
  padding: 0.2vw;
  box-shadow: var(--buttonShadow);
}
.imageName {
  font-size: 0.9vw;
}
.save {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 6vw;
}

.saveIcon {
  color: white;
  font-size: 1.5vw;
}
.uploadImage {
  border-radius: 2px;
  height: 6vh;
  width: 6vw;
  border: 1px solid rgb(206, 206, 206);
}
.img_btn_div {
  display: flex;
  align-items: center;
  width: 30vw;
  margin-left: 17vw;
}

.image_div {
  width: 36vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1vw;
}
.selected_img_div {
  height: 12vh;
}
.img {
  height: 10vh;
  width: 12vw;
}

.imageIcon {
  color: var(--btnColor);
  font-size: 2.5vw;
  background-color: transparent;
  transition: all 0.5s;
}
.imageIcon:hover {
  transform: scale(1.2);
}

/* .lastIconDiv {
  margin-top: 1vh;
} */
.refresh2 {
  font-size: 1.8vw;
}
.delete2 {
  font-size: 1.7vw;
}

.delete_modal {
  margin: 0 auto;
}
.delete_popup_text {
  margin: 0 auto;
  color: #910303;
  font-size: 1vw;
  text-align: center;
  font-weight: bold;
}
.delete_popup_revert_text {
  margin: 0 auto;
  text-align: center;
  font-size: 0.8vw;
}
.delete_modal_btn_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5vw;
}

.delete_modal_buttonCancel {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-size: 0.8vw;
  margin-right: 0.5vw;
  border-radius: 0.5vw;
  width: 4vw;
  background-color: white;
  color: #04aa6d;
  padding: 0.2vw;
  transition: all 0.8s;
  border: none;
}

.delete_modal_buttonCancel:hover {
  background-color: #04aa6d;
  color: white;
}

.delete_modal_buttoDelete {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-size: 0.8vw;
  border-radius: 0.5vw;
  width: 6vw;
  background-color: white;
  color: #aa0404;
  padding: 0.2vw;
  transition: all 0.8s;
  border: none;
  margin-left: 1vw;
}

.delete_modal_buttoDelete:hover {
  background-color: #aa0404;
  color: white;
}

/* .tab_row {
    cursor: pointer;
  }
  
  .tab_row:focus {
    outline: none;
    background-color: green;
  } */

.tab_selected {
  background-color: white;
}

.inputContainer {
  position: relative;
}

.datalist {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  z-index: 1;
}
.inputContainer:hover .datalist {
  display: block; /* Show the datalist when the input container is hovered over */
}
/* .category_option {
  background-color: #04aa6d;
} */
/* //footer =====================================barcode ================================*/

.cash_Footer {
  height: 12.5vh;
  display: flex;
  align-items: center;
  background-color: var(--bg);
  border-radius: 1vw;
  width: 98.5%;
  margin: 0.5vh auto;
  box-shadow: var(--largeCardShadow);
}

.totalDiv {
  background-color: var(--cardBg);
  height: 11vh;
  width: 30.5vw;
  margin-right: 2vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vh;
  box-shadow: var(--smallCardShadow);
  margin-left: 1vw;
  animation-name: effectButtom;
  animation-duration: 1.2s;
  padding: 1vw;
}

.Amount {
  display: flex;
  gap: 1vw;
  align-items: center;
}

.amountebelDiv {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 1.1vw;
  height: 2.5vh;
  width: 9vw;
  /* box-shadow: var(--labelShadow); */
}

.amountInput {
  font-size: 1vw;
  box-shadow: var(--labelShadow);
  width: 14vw;
  height: 2.7vh;
  border: none;
  border-radius: 0.2vw;
  padding: 0.3vw;
  text-align: center;
}

.amountLabel {
  width: 8vw;
  font-size: 0.9vw;
  /* box-shadow: 0 2px 100px 0 rgba(32, 31, 31, 0.5); */
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-weight: bold;
  margin-right: 1vw;
}

.cashOperationDiv {
  height: 20vh;
  /* margin: 0 5vw; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chasOperationBtn {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 6vw;
  animation-name: effectButtom;
  animation-duration: 1.2s;
}
.barcodeIcon {
  color: white;
  font-size: 2vw;
  background-color: transparent;
}

.excelExportDiv {
  margin-left: 24.5vw;

  animation-name: effectButtom;
  animation-duration: 1.2s;
}

.excelExportBtn {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 6vw;
  animation-name: effectButtom;
  animation-duration: 1.2s;
  padding: 0.2vw;
}
.xlIcon {
  color: white;
  font-size: 1.6vw;
  background-color: transparent;
}

/* //rack modal: */
.rackSavemodal {
  background-color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vw;
  flex-direction: column;
}
.rackSavemodal > .sanitory_text {
  width: 80%;
}
.rackSavemodal > .delete_modal_btn_div {
  margin-bottom: 1vh;
  animation-name: effectTop;
  animation-duration: 1s;
}

.delete_modal_buttonCancel2 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-size: 0.8vw;
  border-radius: 0.5vw;
  width: 6vw;
  height: 3vh;
  background-color: var(--btnColor);
  color: white;
  padding: 0.2vw;
  transition: all 0.8s;
  border: none;
  margin-left: 1vw;
}

.delete_modal_buttoDelete2 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-size: 0.8vw;
  border-radius: 0.5vw;
  width: 6vw;
  height: 3vh;
  background-color: var(--btnColor);
  color: white;
  padding: 0.2vw;
  transition: all 0.8s;
  border: none;
  margin-left: 1vw;
}

.divForALlbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 9vw;
}

.buttonText {
  font-size: 1vw;
  font-weight: bold;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  height: 0.4vh;
  color: #000000;
  animation-name: effectButtom;
  animation-duration: 1.2s;
}
.errorBorder {
  border: 2px solid red;
  animation: flickerAnimation 0.7s 4 alternate;
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.nameInput::placeholder {
  font-size: 0.7vw;
}
.productInput::placeholder {
  font-size: 0.7vw;
}
/*================ animation ==========================*/
@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectButtom {
  0% {
    transform: translateY(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes transition_from_left_to_right {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

/* styles.css */
.help-contact-page {
  margin: 0 auto;
  padding: 15px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1vw;
}

.help-contact-page h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.help-contact-page p {
  font-size: 14px;
  margin-bottom: 10px;
}

.help-center p {
  font-size: 25px;
  text-align: center;
}

.help-center {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.faq {
  height: 35vh;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-submit-section {
  display: flex;
  padding: 10px;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ticket-submit-section textarea {
  width: 40.5vw;
  height: 16vh;
  overflow-y: scroll;
  resize: none;
  scrollbar-width: 10px;
  font-size: 1rem;
  padding: 5px;
  border: none;
}
.ticket-submit-section select {
  width: 20vw;
  height: 5vh;
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 1vw;
  padding-left: 0.3vw;
  background-color: #ffffff;
  border: 0.001px solid red;
  border-color: #b1afaf;
  color: #000;
}
.ticket-submit-section button {
  width: 10vw !important;
  font-size: 1.4rem !important;
}
.submit_input {
  display: flex;
  column-gap: 10px;
}
.cusotmer_help_qunation {
  /* margin-bottom: 20px; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 10px 12px;
  background-color: #88b39a;
  display: flex;
  flex-direction: column;
  /* width: 98vw;
    height: 30vh; */
  height: 90vh;
  border-radius: 1vw;
  animation-name: effectTop;
  animation-duration: 1s;
}

.help-center {
  display: flex;
  flex-direction: column;
}

.help-center input {
  width: 20vw;
  height: 5vh;
  box-shadow: 0 0px 0px 1px rgba(145, 141, 141, 0.5);
  border-radius: 3px;
  font-size: 1vw;
  padding-left: 0.3vw;
  background-color: #ffffff;
  border: 0.001px solid red;
  border-color: #b1afaf;
  color: #000;
}

.help-center button {
  width: 5vw;
  margin-left: 0.5vw;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  border-radius: 3px;
  font-size: 2vw;
  background: #06341b;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  border: none;
  color: #ffffff;
}
.contact-page {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.contact-page {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 10px 12px;
  background-color: #88b39a;
  display: flex;
  flex-direction: column;
  /* width: 98vw; */
  height: 90vh;
  border-radius: 1vw;
  animation-name: effectTop;
  animation-duration: 1s;
}

.contact-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
}

.contact-form label {
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;
  font-size: 1vw;
  font-weight: bold;
}

.contact-form input {
  width: 20vw;
  height: 5vh;
  padding-left: 0.3vw;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  margin-bottom: 0.3vw;
}

.submit_form button {
  width: 10vw;
  height: 4vh;
  font-weight: bold;
  font-size: 1vw;
  background-color: #06341b;
  color: #ffffff;
  border: none;
  margin-top: 20px;
}

.asked_quation {
  margin-top: 30px;
  margin-left: 20px;
  font-weight: bold;
  position: relative;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.asked_quation::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 18%;
  border-bottom: 2px solid black;
}

.frequently_asked {
  margin-bottom: 10px;
}

.multiple_qustion_box {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-top: 1vw;
  justify-content: center;
  gap: .8vw;
}

.box {
  width: 30vw;
  height: 7.5vh;
  background-color: #bbd2c6;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 10px 12px;
  font-size: 1.2rem;
  padding-left: 15px;
  padding-top: 3px;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box1 {
  border-top-left-radius: 1vw;
  /* border-bottom-right-radius: 1vw; */
}
.box2 {
  border-bottom-left-radius: 1vw;
}

/* Contact info =================================================================*/

.contact-root-container {
  /* margin-top: 1vh; */
  min-height: 86vh;
  height: 100%;
}

.address-container {
  display: flex;
  flex-direction: column;
}

.map-outside {
  background-color: rgb(62, 65, 63);
  padding: 10px;
  height: 340px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 6px 6px 6px #9e9e9e;
}

.map-container {
  height: 320px;
  width: 100%;
}

.address-outside {
  background-color: rgb(62, 65, 63);
  padding: 10px;
  height: 230px;
  width: 100%;
  margin-right: 100px;
  margin-left: 10px;
  border-radius: 10px;
  box-shadow: 6px 6px 6px #9e9e9e;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 4vw;
}

.location-container {
  height: 210px;
  width: 100%;
  text-align: center;
  background-color: white;
}

.address-header {
  font-size: larger;
}

.address-content {
  font-size: medium;
}

.address-logo {
  height: 50px;
  width: 50px;
}

.contact_info {
  font-size: 1.5vw;
  font-weight: bold;
}

@media screen and (max-width: 575px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
    word-spacing: normal;
  }

  .bank_details_info {
    word-spacing: normal;
  }

  .address-header {
    font-size: medium;
    text-align: center;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .contact-root-container {
    margin-top: 10vh;
    background: #ffffff;
    min-height: 100vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 80px;
  }

  .map-container {
    height: 480px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 500px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 480px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .contact-root-container {
    margin-top: 2vh;
    /* background: #ffffff; */
    min-height: 86vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    /* margin-top: 50px; */
    flex-direction: column;
    width: 80%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 350px;
    width: 115%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 55px;
  }

  .map-container {
    height: 330px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 230px;
    width: 100%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;
  }

  .location-container {
    height: 200px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 50px;
    width: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-root-container {
    margin-top: 1vh;
    /* background: #ffffff; */
    min-height: 86vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 350px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    /* margin-left: 10%;
    margin-right: 10%; */
    margin-bottom: 20px;
  }

  .map-container {
    height: 330px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 230px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 35px;
  }

  .location-container {
    height: 210px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: medium;
    word-spacing: normal;
  }

  .address-content {
    font-size: small;
  }

  .address-logo {
    height: 50px;
    width: 50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .contact-root-container {
    margin-top: 1vh;
    min-height: 86vh;
    height: 100%;
  }

  .address-container {
    display: flex;
    flex-direction: column;
  }

  .map-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 350px;
    width: 93%;
    margin-left: 1vw;
    margin-right: 1vw;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
  }

  .map-container {
    height: 330px;
    width: 100%;
  }

  .address-outside {
    background-color: rgb(62, 65, 63);
    padding: 10px;
    height: 230px;
    width: 93%;
    margin-right: 100px;
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 6px 6px 6px #9e9e9e;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 4.5vw;
  }

  .location-container {
    height: 210px;
    width: 100%;
    text-align: center;
    background-color: white;
  }

  .address-header {
    font-size: larger;
  }

  .address-content {
    font-size: medium;
  }

  .address-logo {
    height: 50px;
    width: 50px;
  }
}

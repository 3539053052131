.full_div_employee_setup {
  display: flex;
  flex-direction: column;
  row-gap: 0.7vh;
  width: 100vw;
  height: 93.5vh;
  overflow: hidden;
  background-color: var(--bodyColor);
}
.full_div_employee_setup ::-webkit-scrollbar {
  width: 0.4vw;
  background-color: var(--bg);
  border-radius: 0.3vw;
}
.full_div_employee_setup ::-webkit-scrollbar-thumb {
  width: 0.5vw;
  background-color: rgb(233, 232, 232);
}
/*================================= first_row_div_employee_setup=========================== */
.first_row_div_employee_setup {
  box-shadow: var(--largeCardShadow);
  background-color: var(--bg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  margin-top: 0.8vw;
  margin-left: 0.8vw;
  column-gap: 1vw;
  padding: 0.7vw 1vw;
  width: 98.5vw;
  height: 22vh;
  margin-bottom: 0.5vh;
}

.input_field_employee_setup {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1vh;
}
.input_field_employee_setup label {
  display: inline-block;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  font-size: 1vw;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  width: 7vw;
  height: 3vh;
  margin-right: 1vw;
}

.input_field_employee_setup input {
  box-shadow: var(--labelShadow);
  border-radius: 3px;
  font-size: 0.8vw;
  padding-left: 0.2vw;
  background-color: #ffffff;
  width: 12vw;
  height: 3.2vh;
  border: 0.001px solid red;
  border-color: #b1afaf;
  animation: 3s;
  /* animation-name: fadeIn;
  animation-fill-mode: forwards; */
}

/* .save_button {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
} */
.saveIcon {
  color: white;
  font-size: 1.8vw;
  background-color: transparent;
}

.forEmployNameDiv_animation {
  background-color: var(--cardBg);
  padding: 1vw;
  height: 6vh;
  margin-left: 0.5vw;
  border-radius: 1vw;
  animation-name: effectLeft;
  animation-duration: 1.2s;
}
.container_create_employee_setup {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  column-gap: 8vw;

  animation-name: effectTop;
  animation-duration: 1s;
}

.container_create_employee_setup > div:nth-child(1),
.container_create_employee_setup > div:nth-child(2),
.container_create_employee_setup > div:nth-child(3) {
  background-color: var(--cardBg);
  height: 14vh;
  padding: 0.6vw;
  border-radius: 1vw;

  animation-name: effectTop;
  animation-direction: 1.2s;
}

/*======================== Second Row Div===================================================== */
.second_row_div_employee_setup {
  box-shadow: var(--largeCardShadow);
  background-color: var(--bg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.8vw;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 98.5vw;
  height: 8vh;
  margin-bottom: 0.5vh;
}
.container_search_employee_setup {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 65vw;
}
.search_button {
  margin-left: 1vw;
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
  padding: 0.1vw;
}
.searchIcon {
  color: white;
  font-size: 1.5vw;
  background-color: transparent;
}

.showAll_button {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
}
.viewAllIcon {
  color: white;
  font-size: 1.8vw;
  background-color: transparent;
  font-weight: bold;
}
.forEmployNameDivButton_animation {
  margin-left: -4vw;
  animation-name: effectRight;
  animation-duration: 1.2s;
}
/*===================== Thrid row Div =======================================================*/
.third_row_div_employee_setup {
  box-shadow: var(--largeCardShadow);
  background-color: var(--bg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 0.8vw;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 98.5vw;
  height: 35vh;
  margin-bottom: 0.5vh;
}

.table-wrapper_employee_setup {
  max-height: 500px;
  overflow-y: scroll;
  box-shadow: var(--smallCardShadow);
  border-radius: 2vh;
  transition: 0.5s;
  padding: 0.5vw;
  column-gap: 1vw;
  width: 96vw;
  height: 32vh;
  background-color: var(--cardBg);
  animation-name: effectLeft;
  animation-duration: 1.2s;
}

.table-wrapper_employee_setup table {
  width: 100%;
  color: white;
  border: none;
}

.employee_table_th {
  background-color: var(--btnColor);
}

.employee_table_td {
  background-color: transparent;
  font-size: 0.8vw;
  border: 0.1px solid rgb(124, 123, 123);
  padding: 0.1vw;
  color: black;
}

.employee_tr {
  background-color: #f0f0f0;
  font-size: 2vw;
}
.employee_tr:hover {
  background-color: #b1afaf;
}
.tab_selected {
  background-color: #06341b !important;
}
.tab_selected > .employee_table_td {
  color: white;
}
/*================================== Forth row div==================================================== */
.forth_row_div_employee_setup {
  box-shadow: var(--largeCardShadow);
  background-color: var(--bg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5vw;
  margin-left: 0.8vw;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 98.5vw;
  height: 24vh;
}
.UpdateText {
  display: flex;
  width: 100%;
  margin-left: 1vw;
  margin-bottom: 0.5vh;
  margin-top: -1vh;
  animation-name: effectButtom;
  animation-duration: 1.2s;
}
.container_update_employee_setup {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  align-items: center;
  justify-content: center;
  column-gap: 4vw;
  padding: 0 0.5vw;
  animation-name: effectButtom;
  animation-duration: 1.2s;
}

.container_update_employee_setup .create_employee_setup_column1 {
  box-shadow: var(--smallCardShadow);
  background-color: var(--cardBg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 25vw;
  height: 16vh;
}
.container_update_employee_setup .create_employee_setup_column2 {
  box-shadow: var(--smallCardShadow);
  background-color: var(--cardBg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 25vw;
  height: 16vh;
}

.container_update_employee_setup .create_employee_setup_column3 {
  box-shadow: var(--smallCardShadow);
  background-color: var(--cardBg);
  border-radius: 2vh;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  column-gap: 1vw;
  padding: 0.7vw;
  width: 25vw;
  height: 16vh;
}

.update_button {
  background-color: var(--btnColor);
  border: none;
  border-radius: 0.5vw;
  box-shadow: var(--buttonShadow);
  width: 5vw;
}
.updateIcon {
  color: white;
  font-size: 1.8vw;
  background-color: transparent;
}
.deleteicon {
  color: rgb(247, 90, 90);
  font-size: 1.8vw;
  background-color: transparent;
}

.divForALlbutton {
  display: flex;
  flex-direction: column;
}
.buttonText {
  text-align: center;
  font-size: 0.8vw;
  height: 0.4vh;
  color: #06341b;
}
/* animation starts from here */
@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectButtom {
  0% {
    transform: translateY(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes transition_from_left_to_right {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

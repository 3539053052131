.full_div_login_page {
  width: 100%;
  position: relative;
  display: inline-block;
}

.img-full-view {
  width: 100%;
  height: 99.5vh;
  object-fit: cover;
  opacity: 0.5;
}

.bg-tranparant-background::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #020202;
  position: absolute;
  top: 0px;
  z-index: 1;
  opacity: 0.7;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.container_super_shop_login {
  width: 25vw;
  height: 5vh;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.container_super_shop_login::after {
  content: "";
  position: absolute;
  width: 25vw;
  height: 55vh;
  background-color: #bbb5b5;
  /* transform: translate(-50%); */
  top: 0px;
  z-index: -1;
  opacity: 0.5;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  border-radius: 1vw;
}

.container_super_shop_all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.super-shop-logo {
  margin-top: 0.5vw;
  width: 30%;
  filter: drop-shadow(0 -6mm 4mm rgb(126, 121, 126));
  margin-bottom: 1.2vw;
}

.heading {
  margin-top: 1vw;
  color: #fff;
  font-size: 2vw;
  font-weight: bold;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.from_super_shop_login {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  border-radius: 1vw;
}

.input_field_super_shop_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vw;
  position: relative;
}

.input_field_super_shop_login input {
  border-radius: 3px;
  padding: 0.2vw 1.5vh;
  font-size: 1vw;
  background-color: #ffffff;
  width: 15vw;
  height: 4vh;
  color: #000;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  border: none;
  animation: 3s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  outline: none;
}

.login-button {
  width: 60%;
  height: 3.8vh;
  border-radius: 1vw;
  border: none;
  font-size: 1vw;
  font-weight: bold;
  background-color: #52c0c0;
  /* background-color: #04aa6d; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}
.icon-login {
  font-size: 1vw;
  color: #000;
  position: absolute;
  right: 2%;
  cursor: pointer;
}

.icon-login:active {
  transform: scale(0.9);
}

.rules_login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  color: #000;
  font-size: 1vw;
  font-weight: bold;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}

.input_field_roles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input_field_roles input[type="radio"] {
  accent-color: #52c0c0;
  width: 1vw;
}

.input_field_roles label {
  color: #070707;
  font-weight: bold;
}

/* animatin */

@keyframes effectLeft {
  0% {
    transform: translateX(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectRight {
  0% {
    transform: translateX(20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes effectTop {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes effectBottom {
  0% {
    transform: translateY(-20vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  5% {
    visibility: hidden;
    opacity: 0.05;
    box-shadow: 2px 2px 125px 2px rgba(145, 141, 141, 0.5);
  }
  10% {
    visibility: visible;
    opacity: 0.1;
    box-shadow: 2px 2px 120px 2px rgba(145, 141, 141, 0.5);
  }
  15% {
    visibility: visible;
    opacity: 0.15;
    box-shadow: 2px 2px 110px 2px rgba(145, 141, 141, 0.5);
  }
  20% {
    visibility: visible;
    opacity: 0.2;
    box-shadow: 2px 2px 100px 2px rgba(145, 141, 141, 0.5);
  }

  25% {
    visibility: hidden;
    opacity: 0.25;
    box-shadow: 2px 2px 95px 2px rgba(145, 141, 141, 0.5);
  }
  30% {
    visibility: visible;
    opacity: 0.3;
    box-shadow: 2px 2px 90px 2px rgba(145, 141, 141, 0.5);
  }
  35% {
    visibility: visible;
    opacity: 0.35;
    box-shadow: 2px 2px 85px 2px rgba(145, 141, 141, 0.5);
  }
  40% {
    visibility: visible;
    opacity: 0.4;
    box-shadow: 2px 2px 80px 2px rgba(145, 141, 141, 0.5);
  }
  45% {
    visibility: hidden;
    opacity: 0.45;
    box-shadow: 2px 2px 75px 2px rgba(145, 141, 141, 0.5);
  }
  50% {
    visibility: visible;
    opacity: 0.5;
    box-shadow: 2px 2px 70px 2px rgba(145, 141, 141, 0.5);
  }
  55% {
    visibility: visible;
    opacity: 0.55;
    box-shadow: 2px 2px 65px 2px rgba(145, 141, 141, 0.5);
  }
  60% {
    visibility: visible;
    opacity: 0.6;
    box-shadow: 2px 2px 60px 2px rgba(145, 141, 141, 0.5);
  }
  65% {
    visibility: visible;
    opacity: 0.65;
    box-shadow: 2px 2px 55px 2px rgba(145, 141, 141, 0.5);
  }
  70% {
    visibility: hidden;
    opacity: 0.7;
    box-shadow: 2px 2px 50px 2px rgba(145, 141, 141, 0.5);
  }
  75% {
    visibility: visible;
    opacity: 0.75;
    box-shadow: 2px 2px 45px 2px rgba(145, 141, 141, 0.5);
  }
  80% {
    visibility: visible;
    opacity: 0.8;
    box-shadow: 2px 2px 40px 2px rgba(145, 141, 141, 0.5);
  }
  85% {
    visibility: visible;
    opacity: 0.85;
    box-shadow: 2px 2px 15px 2px rgba(145, 141, 141, 0.5);
  }
  90% {
    visibility: visible;
    opacity: 0.9;
    box-shadow: 2px 2px 10px 2px rgba(145, 141, 141, 0.5);
  }
  95% {
    visibility: visible;
    opacity: 0.95;
    box-shadow: 2px 2px 5px 2px rgba(145, 141, 141, 0.5);
  }
  100% {
    visibility: visible;
    opacity: 1;
    box-shadow: 2px 2px 4px 2px rgba(145, 141, 141, 0.5);
  }
}

@keyframes transition_from_left_to_right {
  0% {
    transform: translateX(-10vw);
    opacity: 0;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
